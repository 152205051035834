export const routes = {
  HOMEPAGE: {
    name: "Home Page",
    path: "/",
  },
  NEWSPAGE: {
    name: "News Page",
    path: "/category/",
  },
  NEWSPAGE_MAIN: {
    name: "News Page Main",
    path: "/news",
  },
  LOGIN: {
    name: "Login Page",
    path: "/login",
  },
};
