export const userConstants = {
  GET_LATEST_NEWS: "GET_LATEST_NEWS",
  GET_SINGLE_NEWS: "GET_SINGLE_NEWS",
  GET_NEWS_CATEGORY: "GET_NEWS_CATEGORY",
  GET_ENTERTAINMENT_CATEGORY: "GET_ENTERTAINMENT_CATEGORY",
  GET_SPORT_CATEGORY: "GET_SPORT_CATEGORY",
  GET_BUSINESS_CATEGORY: "GET_BUSINESS_CATEGORY",
  GET_CRIME_REPORT_CATEGORY: "GET_CRIME_REPORT_CATEGORY",
  GET_POLITICS_CATEGORY: "GET_POLITICS_CATEGORY",
  GET_OPINION_CATEGORY: "GET_OPINION_CATEGORY",
  GET_MAIN_NEWS: "GET_MAIN_NEWS",
  GET_CLICKED_NEWS: "GET_CLICKED_NEWS",
  GET_NEWS_COMMENTS: "GET_NEWS_COMMENTS",
};
