import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { BASE_API_URL, newsAction } from "../../redux/action/newsAction";
import { routes } from "../../routes";
import axios from "axios";
// import { getNewsClicks } from "../card/Card";
import "./entertainment.scss";
import { useDispatch } from "react-redux";
import Skeleton from "react-loading-skeleton";
export const convertToSlug = (input) => {
  if (typeof input === "string") {
    let slug = input
      ?.trim()
      ?.toLocaleLowerCase()
      ?.replace(/\s/g, "-")
      ?.replace(/,/g, "-")
      ?.replace(/'|"~|@/g, "-")
      ?.replace(/"/g, "-")
      ?.replace(/[^\w\s_.,-/#-'"]/g, "-")
      ?.split("-");
    slug = slug
      .map((el) => el.trim())
      .filter((el) => el !== "")
      .join("-");
    return slug;
  }
};
//  let slug = input
//    ?.trim()
//    ?.toLocaleLowerCase()
//    ?.replace(/\s/g, "-")
//    ?.replaceAll(",", "-")
//    ?.replace(/[^\w\s_.,-/#-'"]/g, "-")
//    ?.split("-");
export const convertDate = (date) => {
  return moment(date).format("LL");
};
function Entertainment() {
  const [entertainmentNews, setEntertainmentNews] = React.useState([]);
  const [loading, setLoading] = useState(true);
  // const entertainmentNews = useSelector((el) => el?.categoryEntertainmnet);
  const lx = 0;
  // const clickedNews = useSelector((el) => el?.clickedNews);
  const disaptch = useDispatch();

  const isDispatchedRef = useRef(false);
  const handleClicks = (id) => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        let payload = {
          id,
          ip: data.ip,
        };
        if (!isDispatchedRef.current) {
          disaptch(newsAction.postClickedNews(payload));
          disaptch(newsAction.getSingleNews(id));
          isDispatchedRef.current = true;
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  useEffect(() => {
    axios
      .get(`${BASE_API_URL}/news/?category=entertainment&page=1`)
      .then((res) => {
        setEntertainmentNews(res.data.payload);
        setLoading(false);
      });
  }, []);
  if (loading) {
    return <Skeleton height={300} />;
  }
  return (
    <div className="entertainment">
      <h1>Entertainment</h1>
      <div className="flex-container">
        <Link
          to={
            routes.NEWSPAGE_MAIN.path +
            "/" +
            convertToSlug(entertainmentNews[lx]?.title)
          }
          className="item1"
          onClick={() => handleClicks(entertainmentNews[lx]?.newsSlug)}
        >
          <img
            src={
              typeof entertainmentNews[lx]?.image === "object"
                ? entertainmentNews[lx]?.image[0]
                : entertainmentNews[lx]?.image
            }
            alt="img.jpg"
          />
          <div className="text-container">
            <div>
              <h5 className="nation-badge">ENTERTAINMENT</h5>
            </div>
            <h3>{entertainmentNews[lx]?.title}</h3>
            <div className="detail-flex">
              <small>
                BY{" "}
                <a href="/">{entertainmentNews[lx]?.author?.toUpperCase()}</a>
              </small>
              <small className="svg-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  fill="currentColor"
                  class="bi bi-clock"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                  <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                </svg>
                {convertDate(entertainmentNews[lx]?.createdAt)}
              </small>
              <small className="svg-flex">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="10"
                  height="10"
                  fill="currentColor"
                  class="bi bi-chat"
                  viewBox="0 0 16 16"
                >
                  <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                </svg>
                0
              </small>
              <small
                style={{ display: "flex", gap: "5px", alignItems: "center" }}
              >
                <i class="fa fa-eye" aria-hidden="true">
                  {entertainmentNews[lx]?.views}
                </i>
              </small>
            </div>
          </div>
        </Link>
        {entertainmentNews[1] && (
          <Link
            className="item2"
            to={
              routes.NEWSPAGE_MAIN.path +
              "/" +
              convertToSlug(entertainmentNews[1]?.title)
            }
            onClick={() => handleClicks(entertainmentNews[1]?.newsSlug)}
          >
            <img
              src={
                typeof entertainmentNews[1]?.image === "object"
                  ? entertainmentNews[1]?.image[0]
                  : entertainmentNews[1]?.image
              }
              alt="img.jpg"
            />
            <div className="text-container">
              <div>
                <h5 className="nation-badge">ENTERTAINMENT</h5>
              </div>
              <h3>{entertainmentNews[1]?.title}</h3>
              <div className="detail-flex">
                <small className="svg-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    fill="currentColor"
                    class="bi bi-clock"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                    <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                  </svg>
                  {convertDate(entertainmentNews[1]?.createdAt)}
                </small>
                <small className="svg-flex">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="10"
                    height="10"
                    fill="currentColor"
                    class="bi bi-chat"
                    viewBox="0 0 16 16"
                  >
                    <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                  </svg>
                  0
                </small>
                <small
                  style={{ display: "flex", gap: "5px", alignItems: "center" }}
                >
                  <i class="fa fa-eye" aria-hidden="true">
                    {entertainmentNews[1]?.views}
                  </i>
                </small>
              </div>
            </div>
          </Link>
        )}
        {entertainmentNews[2] && (
          <div className="item3">
            <Link
              to={
                routes.NEWSPAGE_MAIN.path +
                "/" +
                convertToSlug(entertainmentNews[2]?.title)
              }
              onClick={() => handleClicks(entertainmentNews[2]?.newsSlug)}
              className="sub-item1"
            >
              <img
                src={
                  typeof entertainmentNews[2]?.image === "object"
                    ? entertainmentNews[2]?.image[0]
                    : entertainmentNews[2]?.image
                }
                alt="img.jpg"
              />
              <div className="text-container">
                <div>
                  <h5 className="nation-badge">ENTERTAINMENT</h5>
                </div>
                <h3>{entertainmentNews[2]?.title}</h3>
                <div className="detail-flex">
                  <small className="svg-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="currentColor"
                      class="bi bi-clock"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                      <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                    </svg>
                    {convertDate(entertainmentNews[2]?.createdAt)}
                  </small>
                  <small className="svg-flex">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="10"
                      height="10"
                      fill="currentColor"
                      class="bi bi-chat"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                    </svg>
                    0
                  </small>
                  <small
                    style={{
                      display: "flex",
                      gap: "5px",
                      alignItems: "center",
                    }}
                  >
                    <i class="fa fa-eye" aria-hidden="true">
                      {entertainmentNews[2]?.views}
                    </i>
                  </small>
                </div>
              </div>
            </Link>
            {entertainmentNews[3] && (
              <Link
                to={
                  routes.NEWSPAGE_MAIN.path +
                  "/" +
                  convertToSlug(entertainmentNews[3]?.title)
                }
                onClick={() => handleClicks(entertainmentNews[3]?.newsSlug)}
                className="sub-item2"
              >
                <img
                  src={
                    typeof entertainmentNews[3]?.image === "object"
                      ? entertainmentNews[3]?.image[0]
                      : entertainmentNews[3]?.image
                  }
                  alt="img.jpg"
                />
                <div className="text-container">
                  <div>
                    <h5 className="nation-badge">ENTERTAINMENT</h5>
                  </div>
                  <h3>{entertainmentNews[3]?.title}</h3>
                  <div className="detail-flex">
                    <small className="svg-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        class="bi bi-clock"
                        viewBox="0 0 16 16"
                      >
                        <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71V3.5z" />
                        <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0z" />
                      </svg>
                      {convertDate(entertainmentNews[3]?.createdAt)}
                    </small>
                    <small className="svg-flex">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="10"
                        height="10"
                        fill="currentColor"
                        class="bi bi-chat"
                        viewBox="0 0 16 16"
                      >
                        <path d="M2.678 11.894a1 1 0 0 1 .287.801 10.97 10.97 0 0 1-.398 2c1.395-.323 2.247-.697 2.634-.893a1 1 0 0 1 .71-.074A8.06 8.06 0 0 0 8 14c3.996 0 7-2.807 7-6 0-3.192-3.004-6-7-6S1 4.808 1 8c0 1.468.617 2.83 1.678 3.894zm-.493 3.905a21.682 21.682 0 0 1-.713.129c-.2.032-.352-.176-.273-.362a9.68 9.68 0 0 0 .244-.637l.003-.01c.248-.72.45-1.548.524-2.319C.743 11.37 0 9.76 0 8c0-3.866 3.582-7 8-7s8 3.134 8 7-3.582 7-8 7a9.06 9.06 0 0 1-2.347-.306c-.52.263-1.639.742-3.468 1.105z" />
                      </svg>
                      0
                    </small>
                    <small
                      style={{
                        display: "flex",
                        gap: "5px",
                        alignItems: "center",
                      }}
                    >
                      <i class="fa fa-eye" aria-hidden="true">
                        {entertainmentNews[3]?.views}
                      </i>
                    </small>
                  </div>
                </div>
              </Link>
            )}
          </div>
        )}
      </div>
    </div>
  );
}

export default Entertainment;
